export const data = {
  headerTitle: "Create a Passport Form in 5 Minutes",
  metadesc: "Simplified Document Editing for Passport Forms",
  billaboardBg: "/images/forms_bg.jpg",
  billaboardH1: "Get Your U.S. Passport",
  billaboradSubtitle: "Need a new passport right away? <br/><b>Request yours today.</b>",
  billaboardImageOnRight: "/images/passport-banner.png",
  ctaText: "Apply Now",
  ctaUrl: "/",
  stepsTitle: "How to Apply",
  listTitle: "Advantages of applying through our site include:",
  copyTitle: "Simplify the Application Process With Us",
  copyText: "Our service simplifies the process of applying for a U.S. passport.",
};

export const features = [
  "Easy-to-follow application instructions",
  "Complete review of your form",
  "Free corrections if needed",
  "User-friendly website optimized for mobile devices",
  "Round-the-clock assistance via email or contact form",
  "Live phone and chat support",
  "Passport insurance for loss or theft",
];

export const steps = [
  { title: "Select the correct passport application form" },
  { title: "Complete and submit your application online" },
  { title: "Get your new passport" },
];

export const benefits = [
  {
    icon: "/svg/customer.svg",
    text: "We provide extra attention to detail to ensure that <b>the process is stress-free</b>",
  },
  {
    icon: "/svg/24hours.svg",
    text: "Choose our services to make your passport application a seamless experience and be ready o take off anytime.",
  },
];
