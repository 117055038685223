import React from "react";
import { ReactSVG } from "react-svg";
import classnames from "classnames";
import "./styles.scss";
import Wrapper from "../../Layout/Wrapper/wrapper";
import sanitizeHTML from "../../utils/sanitizeHtml";

const BenefitsBanner = ({ benefitsArray, className }) => {
  const classNames = classnames(`BenefitsBanner__section`, {
    [className]: typeof className === "string" && className.length > 0,
  });

  return (
    <section className={classNames}>
      <Wrapper classNames="BenefitsBanner__wrapper BenefitsBanner__wrapper--center">
        {benefitsArray.map((benefit, index) => (
          <div
            key={`benefit-${index}`}
            className="BenefitsBanner__benefit">
            <ReactSVG
              src={benefit.icon}
              className="BenefitsBanner__benefitIcon"
            />
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(benefit.text),
              }}
            />
          </div>
        ))}
      </Wrapper>
    </section>
  );
};

export default BenefitsBanner;
