import React from "react";
import { Layout } from "../../components/Layout/layout";
import { Helmet } from "react-helmet";
import Billboard from "../../components/organisms/Billboard";
import BenefitsBanner from "../../components/organisms/BenefitsBanner";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import List from "../../components/molecules/List";
import Steps from "../../components/molecules/Steps";
import { ReactSVG } from "react-svg";
import Button from "../../components/atoms/Button";
import { data, features, steps, benefits } from "../../data/us-passport";
import { Title } from "../../components/atoms/Title/title";
import "./styles.scss";

const doneIcon = (
  <ReactSVG
    src="/svg/check-with-rouded-bg.svg"
    className="List__icon"
  />
);

const UsPassport = () => {
  return (
    <Layout
      headerTitle={data.headerTitle}
      headerLanding>
      <Helmet>
        <Title>{data.headerTitle}</Title>
        <meta
          name="description"
          content={data.metadesc}
        />
      </Helmet>

      <Billboard
        backgroundImageUrl={data.billaboardBg}
        title={data.billaboardH1}
        subtitle={data.billaboradSubtitle}
        imageOnRight={data.billaboardImageOnRight}
        className="usPassport__billboard"
        component={
          <Button
            label={data.ctaText}
            primary
            link={data.ctaUrl}
            className="usPassport__button"
            dataQa={`home-us-passport`}
          />
        }
      />
      <div className="usPassport__section">
        <Wrapper classNames="usPassport__wrapper">
          {
            <Steps
              centered
              title={data.stepsTitle}
              stepsArray={steps}
            />
          }
        </Wrapper>
      </div>

      <div className="usPassport__section">
        <Wrapper classNames="usPassport__wrapper usPassport__wrapper--simplify usPassport__wrapper--center">
          <div className="usPassport__half">
            <Title
              medium
              tag="h2"
              content={data.copyTitle}
            />
            <p>{data.copyText}</p>
          </div>
          <div className="usPassport__half">
            <List
              copy={data.listTitle}
              list={features}
              icon={doneIcon}
            />
          </div>
        </Wrapper>
      </div>
      <BenefitsBanner benefitsArray={benefits} />
    </Layout>
  );
};

export default UsPassport;
